import styled from 'styled-components';


const DiscountContent = styled.div`
    display: flex;
    flex-direction: row;
    margin: ${ ( { theme } ) => `${ theme.spacing08 }`} 0;
    padding: ${ ( { theme } ) => `${ theme.spacing07 } ${ theme.spacing06 }` };
    justify-content: space-between;
    border-top: 1px dashed ${ ( { theme } ) => theme.ui09 };
    align-items: center;
    min-height: 62px;
`;

export default DiscountContent;
