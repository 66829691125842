import translations from './translations';
import { ASSETS_DOCS } from '@/constants/paths';
import { MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER, } from '@/constants/modules';

export const NAVIGATION = [
    {
        home: {
            id: 'ebooks',
            url: '/#ebooks',
            label: translations.navigationEbooks,
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE ],
            isEnabled: true,
        },
    },
    {
        prize: {
            id: 'wygraj-nagrody',
            url: 'wygraj-nagrody',
            label: translations.navigationPrize,
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER],
            isEnabled: false,
        },
    },
    {
        discounts: {
            id: 'znizki',
            url: 'znizki',
            label: translations.navigationDiscounts,
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER],
            isEnabled: true,
        },
    },
    {
        faq: {
            id: 'faq',
            url: 'faq',
            label: translations.navigationFAQ,
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER ],
            isEnabled: true,
        },
    },
    {
        testimonials: {
            id: 'opinie',
            url: '/#opinie',
            label: translations.navigationTestimonials,
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER],
            isEnabled: true,
        },
    },
    {
        about: {
            id: 'o-travel-pack',
            url: 'o-travel-pack',
            label: translations.navigationAbout,
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER],
            isEnabled: true,
        },
    },
    {
        social: {
            url: 'https://www.instagram.com/travelpack.shop',
            label: 'IG',
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER ],
            isExternal: true,
            isEnabled: true,
        },
    },
    {
        terms: {
            url: `${ASSETS_DOCS}//travel_pack_regulamin.pdf`,
            label: translations.navigationTermsAndConditions,
            module: [ MODULE_HEADER_MOBILE, MODULE_FOOTER ],
            isExternal: true,
            isEnabled: true,
        },
    },
    {
        privacy: {
            url: `${ASSETS_DOCS}/travel_pack_polityka_prywatnosci.pdf`,
            label: translations.navigationPrivacy,
            module: [ MODULE_HEADER_MOBILE, MODULE_FOOTER ],
            isExternal: true,
            isEnabled: true,
        },
    },
];

export const TRAVEL_PACK_URL_BUY = 'https://sowl.co/kce5F'
