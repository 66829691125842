import React from 'react';

import { ASSETS } from '@/constants/paths';
import AvatarWrapper from '../styled/AvatarWrapper';

const Avatar = ( {
                     src,
                     order,
                     title,
                     size,
                 } ) => {

    const handleOnError = ( e ) => {
        e.target.src = `${ ASSETS }/avatar_9.gif`;
    };

    return (
        <AvatarWrapper key={ order } order={ order } size={ size }>
            <img src={ src } alt={ title } title={ title } onError={ handleOnError }/>
        </AvatarWrapper>
    );
};

export default Avatar;
