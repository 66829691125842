import styled from 'styled-components';


const RowWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${ ( { direction = 'row' } ) => direction };;
    justify-content: ${ ( { justifyContent = 'center' } ) => justifyContent };
    align-items: ${ ( { justifyContent = 'center' } ) => justifyContent };

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        flex-direction: ${ ( {
                                 direction = 'column',
                                 isMobileReverted
                             } ) => isMobileReverted ? 'column-reverse' : direction };
    }
    
    @media print {
        page-break-after: always;
    }
`;

export default RowWrapper;
