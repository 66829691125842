import { css } from 'styled-components';

export const getSize = ( { size } ) => {
    switch ( size ) {
        case 'medium':
            return css`
                height: 72px;
                width: 72px;
                min-height: 72px;
                min-width: 72px;
            `;
        case 'large':
            return css`
                height: 100px;
                width: 100px;
                min-height: 100px;
                min-width: 100px;
            `;
        default:
            return css`
                height: 72px;
                width: 72px;
                min-height: 72px;
                min-width: 72px;
            `;
    }
};

export default getSize;
