import React from 'react';

import Text from '@/components/Text';
import Spacer from '@/components/Spacer';
import Avatar from '@/components/Avatar';
import Section from '@/components/Section';
import Headline from '@/components/Headline';
import { Col, Row } from '@/components/Layout';


import discountsList from '@/data/discountsList';
import { ASSETS, ASSETS_BRANDS, AUTHORS_AVATAR } from '@/constants/paths';

import translations from '../../DiscountsList/constants/translations';
import { DiscountBannerImage, DiscountBrandAvatarGroup } from '../styled';

export const DiscountBanner = () => {
    const uniqueBrands = Array.from( new Set( discountsList.map( item => item.name ) ) )
        .map( name => discountsList.find( item => item.name === name ) );
    return (
        <Section id="nagdrody">
            <Row>
                <Col number={ 1 }>
                    <Headline
                        subHeading={ translations.discountsSubHeadline }>{ translations.discountsHeadline }</Headline>
                    <Spacer size="small"/>
                    <Text size="medium">
                        Razem z paczką e-booków otrzymujesz dostęp do ekskluzywnych zniżek od znanych marek –
                        od <strong>5% do nawet 50%!</strong> Zgarnij zniżkę na wyprawy dla podróżników, <strong>obozy
                        surferskie</strong>, <strong>obiekty noclegowe</strong>, atrakcje turystyczne czy <strong>sesje
                        zdjęciowe</strong>. Otrzymujesz od nas także rabaty na kosmetyki, presety do zdjęć, a nawet
                        zniżki na <strong>wyjścia do restauracji</strong> - to wszystko czeka na Ciebie.
                    </Text>
                    <Spacer size="small"/>
                </Col>
            </Row>
            <DiscountBrandAvatarGroup>
                { uniqueBrands.map( ( item, index ) =>
                    ( Array.isArray( item?.profileImg ) ? item?.profileImg : [ item?.profileImg ] ).map( ( avatar, index ) => {
                        const profileImgPath = avatar ? `${ AUTHORS_AVATAR }/${ ASSETS_BRANDS }/${ avatar }` : null;
                        return (
                            <a href={ item?.socialLink || item?.profileUrl } target="_blank" rel="noreferrer">
                                <Avatar
                                    size="medium"
                                    key={ index }
                                    order={ index }
                                    title={ Array.isArray( item?.brandName ) ? item?.brandName[ index ] : item?.brandName }
                                    src={ profileImgPath }
                                />
                            </a>
                        );
                    } )
                ) }
            </DiscountBrandAvatarGroup>
            <Row>
                <Col number={ 1 }>
                    <DiscountBannerImage src={ `${ ASSETS }/discount_banner.png` }
                                         alt={ translations.discountsHeadline }/>
                </Col>
            </Row>
        </Section>
    );
};


export default DiscountBanner;
