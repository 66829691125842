import React from 'react';
import Headline from '@/components/Headline';
// import Text from '@/components/Text';
import Section from '@/components/Section';
import { Col, Row } from '@/components/Layout';
import discountsList from '@/data/discountsList';
import { ASSETS_BRANDS, PROMO_PRODUCTS_IMG } from '@/constants/paths';

import translations from '../constants/translations';
import DiscountItem from './DiscountItem';

const DiscountsList = () => {
    return (
        <Section>
            <Headline subHeading={ translations.discountsSubHeadline }>{ translations.discountsHeadline }</Headline>
            {/*<Text align="center">{translations.discountDisclaimer}</Text>*/}
            <Row>
                { discountsList.map( ( item, index ) => {
                    const promoProductImg = item?.productImg && `${ PROMO_PRODUCTS_IMG }/${ item?.productImg }`;

                    return (
                        <Col number={ 2 } key={ index }>
                            <DiscountItem
                                index={ index }
                                avatar={ `${ ASSETS_BRANDS }/${ item?.profileImg }` }
                                image={ promoProductImg }
                                description={ item?.discountText }
                                brandName={ item?.name }
                                socialLink={ item?.socialLink }
                                profileUrl={ item?.profileUrl }
                                discountCode={ item?.discountCode }
                                discountStartDate={ item?.discountStartDate }
                                discountEndDate={ item?.discountEndDate }
                                discountInstruction={ item?.discountInstruction }
                                showPromoCode={ true }
                            />
                        </Col>
                    );
                } ) }
            </Row>
        </Section>
    );
};

export default DiscountsList;
