export const discountsList = [
    {
        "name": "AlohaCamp",
        "profileImg": [
            "alohacamp_logo2.jpg"
        ],
        "productImg": "alohacamp_product2.jpg",
        "profileUrl": "https://alohacamp.com/pl",
        "socialLink": "https://www.instagram.com/alohacamp_pl/",
        "discountText": "150 zł na pierwszą rezerwację z AlohaCamp za min. 1200 zł.",
        "discountCode": "TRAVELPACK150",
        "discountInstruction": "Do wykorzystania na rezerwację w dowolnym obiekcie na platformie AlohaCamp",
        "discountStartDate": "",
        "discountEndDate": "07/05/2025"
    },
    {
        "name": "AlohaCare",
        "profileImg": [
            "alohacare_logo2.jpg"
        ],
        "productImg": "alohacare_product2.jpg",
        "profileUrl": "https://alohacare.store",
        "socialLink": "https://www.instagram.com/alohacarepolska/",
        "discountText": "Zniżka: 20% na koszyk",
        "discountCode": "TRAVELPACK",
        "discountInstruction": "Do wykorzystania w sklepie internetowym",
        "discountStartDate": "",
        "discountEndDate": "28/02/2025"
    },
    {
        "name": "HEYDUDE",
        "profileImg": [
            "heydude_logo2.jpg"
        ],
        "productImg": "heydude_product2.jpg",
        "profileUrl": "https://www.heydude.pl",
        "socialLink": "https://www.instagram.com/heydudepolska",
        "discountText": "15% na nieprzecenione obuwie na stronie",
        "discountCode": "TRAVELPACK",
        "discountInstruction": "Do wykorzystania w sklepie internetowym",
        "discountStartDate": "",
        "discountEndDate": "31/03/2025"
    },
    {
        "name": "Resibo",
        "profileImg": [
            "resibo_logo2.jpg"
        ],
        "productImg": "resibo_product2.jpg",
        "profileUrl": "https://resibo.pl/",
        "socialLink": "https://www.instagram.com/resibobynature/",
        "discountText": "25% na wszystko – nie łączy się z innymi promocjami, nie obejmuje nowości, kart podarunkowych i boxa urodzinowego",
        "discountCode": "RESIBO25",
        "discountInstruction": "Do wykorzystania w sklepie internetowym",
        "discountStartDate": "17/02/2025",
        "discountEndDate": "28/02/2025"
    },
    {
        "name": "Ohana.surfcamp",
        "profileImg": [
            "ohana.surfcamp_logo2.jpg"
        ],
        "productImg": "ohana.surfcamp_product2.jpg",
        "profileUrl": "https://ohanasurfcamp.pl",
        "socialLink": "https://www.instagram.com/ohana.surfcamp/",
        "discountText": "Zniżka 35 euro (ok. 150 zł) na obóz surferski Ohana Surf Camp w Maroku lub na Maderze",
        "discountCode": "TRAVELPACK35",
        "discountInstruction": "Zgłosić się bezpośrednio do firmy",
        "discountStartDate": "",
        "discountEndDate": "31/12/2025"
    },
    {
        "name": "Zielonyplecak",
        "profileImg": [
            "zielonyplecak_logo2.jpg"
        ],
        "productImg": "zielonyplecak_product2.jpg",
        "profileUrl": "https://www.zielonyplecak.pl/",
        "socialLink": "https://www.instagram.com/zielonyplecak/",
        "discountText": "500 zł zniżki na wyprawę zorganizowaną do Kolumbii",
        "discountCode": "WPS500",
        "discountInstruction": "Zgłosić się bezpośrednio do firmy",
        "discountStartDate": "15/02/2025",
        "discountEndDate": "31/07/2025"
    },
    {
        "name": "Roksanawieczorek",
        "profileImg": [
            "roksanawieczorek_logo2.jpg"
        ],
        "productImg": "roksanawieczorek_product2.jpg",
        "profileUrl": "",
        "socialLink": "https://www.instagram.com/roksanawieczorek/",
        "discountText": "Zniżka 50% na pomoc w organizacji wyjazdu",
        "discountCode": "TRAVELPACK",
        "discountInstruction": "Zgłosić się na Instagramie lub mailowo/ telefonicznie",
        "discountStartDate": "",
        "discountEndDate": "31/12/2025"
    },
    {
        "name": "Traveliway",
        "profileImg": [
            "traveliway_logo2.jpg"
        ],
        "productImg": "traveliway_product2.jpg",
        "profileUrl": "https://www.instagram.com/madeiramoments_photographer?igsh=MWpvdDN2OXMzc2pqcA%3D%3D&utm_source=qr&fbclid=IwY2xjawINk7pleHRuA2FlbQIxMAABHXvDkIuZNmF6iydJy--zJHWhVClv8C26BAwvDbM5UqmUUFz4gKk1mQekpA_aem__SX2aCU1T1OUHGgKxfl_rg",
        "socialLink": "https://www.instagram.com/traveliway/",
        "discountText": "Zniżka 10 euro na sesje zdjęciową na Maderze",
        "discountCode": "TRAVELPACK",
        "discountInstruction": "Zgłosić się na Instagramie lub mailowo/ telefonicznie",
        "discountStartDate": "",
        "discountEndDate": "31/12/2025"
    },
    {
        "name": "Złoty Jar",
        "profileImg": [
            "zloty_jar_logo2.jpg"
        ],
        "productImg": "zloty_jar_product2.jpg",
        "profileUrl": "https://zlotyjar.pl/",
        "socialLink": "https://www.instagram.com/zloty_jar/",
        "discountText": "100 zł zniżki na noclegi w Złotym Jarze",
        "discountCode": "TRAVELPACK",
        "discountInstruction": "Zgłosić się z Voucherem + Nr zamówienia TravelPack'a",
        "discountStartDate": "",
        "discountEndDate": "30/04/2025"
    },
    {
        "name": "Złoty Jar",
        "profileImg": [
            "zloty_jar_logo2.jpg"
        ],
        "productImg": "zloty_jar_restauracja_product2.jpg",
        "profileUrl": "https://zlotyjar.pl/restauracja/",
        "socialLink": "https://www.instagram.com/zloty_jar/",
        "discountText": "50 zł zniżki przy biesiadowaniu w Złotym Jarze powyżej 200zł",
        "discountCode": "TRAVELPACK",
        "discountInstruction": "Zgłosić się z Voucherem + Nr zamówienia TravelPack'a",
        "discountStartDate": "",
        "discountEndDate": "30/04/2025"
    },
    {
        "name": "Kopalania Złota - Złoty Stok",
        "profileImg": [
            "kopalnia_zlota_logo2.jpg"
        ],
        "productImg": "kopalnia_zlota_product2.jpg",
        "profileUrl": "https://kopalniazlota.pl/",
        "socialLink": "",
        "discountText": "2x Wejście w cenie biletu ulgowego, zamiast w cenie biletu normalnego",
        "discountCode": "TRAVELPACK",
        "discountInstruction": "Zgłosić się z Voucherem + Nr zamówienia TravelPack'a",
        "discountStartDate": "",
        "discountEndDate": "30/04/2025"
    },
    {
        "name": "Stara Kruszarnia",
        "profileImg": [
            "stara_kruszarnia_logo2.jpg"
        ],
        "productImg": "stara_kruszarnia_product2.jpg",
        "profileUrl": "",
        "socialLink": "https://www.instagram.com/stara_kruszarnia/",
        "discountText": "50 zł zniżki na biesiadowanie w starej Kruszarni powyżej 200zł",
        "discountCode": "TRAVELPACK",
        "discountInstruction": "Zgłosić się z Voucherem + Nr zamówienia TravelPack'a",
        "discountStartDate": "",
        "discountEndDate": "30/04/2025"
    },
    {
        "name": "Coupleaway",
        "profileImg": [
            "coupleaway_logo2.jpg"
        ],
        "productImg": "coupleaway_ebook_product2.jpg",
        "profileUrl": "https://coupleaway.com/sklep/",
        "socialLink": "https://www.instagram.com/couple.away/",
        "discountText": "10% zniżki na wszystkie przewodniki na stronie",
        "discountCode": "Travel10",
        "discountInstruction": "Do wykorzystania w sklepie internetowym https://coupleaway.com/sklep/",
        "discountStartDate": "",
        "discountEndDate": "31/03/2025"
    },
    {
        "name": "Coupleaway",
        "profileImg": [
            "coupleaway_logo2.jpg"
        ],
        "productImg": "coupleaway_product2.jpg",
        "profileUrl": "https://coupleaway.com/sklep/",
        "socialLink": "https://www.instagram.com/couple.away/",
        "discountText": "15% zniżki na wszystkie presety na stronie",
        "discountCode": "Travel15",
        "discountInstruction": "Do wykorzystania w sklepie inetrenetowym https://coupleaway.com/sklep/",
        "discountStartDate": "",
        "discountEndDate": "31/03/2025"
    },
    {
        "name": "Rudeiczarne",
        "profileImg": [
            "rudeiczarne_logo2.jpg"
        ],
        "productImg": "rudeiczarne_product2.jpg",
        "profileUrl": "https://rudeiczarne.pl/sklep/",
        "socialLink": "https://www.instagram.com/rudeiczarne_travelblog/",
        "discountText": "20% zniżki na maltańskie ebooki na stronie",
        "discountCode": "TRAVELPACK20",
        "discountInstruction": "Do wykorzystanie w sklepie internetowym rudeiczarne.pl",
        "discountStartDate": "",
        "discountEndDate": "15/03/2025"
    },
    {
        "name": "Cozy Izeria",
        "profileImg": [
            "cozy_izeria_logo2.jpg"
        ],
        "productImg": "cozy_izeria_product2.jpg",
        "profileUrl": "https://cozyizeria.pl",
        "socialLink": "https://www.instagram.com/cozy_izeria/",
        "discountText": "100 zł zniżki przy rezerwacji weekendowej; 5% zniżki przy rezerwacji powyżej 5 dni *z wyłączeniem świąt, długich weekendów",
        "discountCode": "TRAVELPACK",
        "discountInstruction": "Zgłosić się mailowo lub telefonicznie",
        "discountStartDate": "",
        "discountEndDate": "31/12/2025"
    },
    {
        "name": "Morze Sasino",
        "profileImg": [
            "morze_sasino_logo2.jpg"
        ],
        "productImg": "morze_sasino_product2.jpg",
        "profileUrl": "https://morzesasino.pl",
        "socialLink": "https://www.instagram.com/morzesasino/",
        "discountText": "100 zł zniżki przy rezerwacji na minimum 3 doby; 5% zniżki przy rezerwacji powyżej 5 dni *z wyłączeniem świąt, długich weekendów",
        "discountCode": "TRAVELPACK",
        "discountInstruction": "Zgłosić się mailowo lub telefonicznie",
        "discountStartDate": "",
        "discountEndDate": "31/12/2025"
    },
    {
        "name": "Don Coaching",
        "profileImg": [
            "don_coaching_logo2.jpg"
        ],
        "productImg": "don_coaching_product2.jpg",
        "profileUrl": "https://doncoaching.com/",
        "socialLink": "https://www.instagram.com/don_coaching/",
        "discountText": "50 euro zniżki na obozy surferskie",
        "discountCode": "TravelPackDC",
        "discountInstruction": "Zgłosić się mailowo lub telefonicznie",
        "discountStartDate": "",
        "discountEndDate": "30/04/2025"
    }
];

export default discountsList;
