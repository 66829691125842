import { SITE_NAME } from '@/constants/globals';
import { ASSETS_DOCS } from '@/constants/paths';

export const translations = {
    contestHeadline: `KONKURS`,
    contestSubHeadline: `Z ${SITE_NAME} Po Nagrody`,
    contestTextMain01: `Kupując paczkę masz szansę wygrać niesamowite nagrody! Wyobraź sobie <strong>voucher o wartości 1200 zł</strong> na wymarzony lot w dowolne miejsce, niezapomniany <strong>pobyt w urokliwych domkach</strong> nad jeziorem Nyskim oraz wiele innych fantastycznych niespodzianek. <br/><br/> To wyjątkowa okazja, by spełnić podróżnicze marzenia i stworzyć wspomnienia na całe życie!`,
    contestHeadlineItem01: 'Voucher Na Lot  1200 zł',
    contestHeadlineItem02: '2 Noce W Domku Nad Jeziorem',
    contestHeadlineItem03: 'Dziennik Podróżniczy',
    contestHeadlineItem04: 'Zestaw Kosmetyków',
    contestHeadlineNumber01: '1x',
    contestHeadlineNumber02: '2x',
    contestHeadlineNumber03: '3x',
    contestButtonOptIn: `WEŹ UDZIAŁ W KONKURSIE`,
    discountButtonMore: `ZOBACZ WSZYSTKIE ZNIŻKI`,
    contestButtonShowMore: `ZOBACZ WIĘCEJ`,
    contestPrizeLinkItem02: 'https://www.instagram.com/moderndomki',
    contestPrizeLinkItem03: 'https://www.instagram.com/ohworld.store',
    contestPrizeLinkItem04: 'https://www.instagram.com/veoli_botanica',
    contestPrizeLinkWWWItem02: 'https://www.booking.com/hotel/pl/domek-jezioro-nyskie.en-gb.html?aid=318615&label=New_English_EN_MU_26767354105-_Gaq7mTz_PDvcNMyADPShwS217244031990%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atidsa-64415224945%3Alp1010224%3Ali%3Adec%3Adm&sid=efde85b1b4e51c0f780ce86dde1de951&all_sr_blocks=696167801_328696091_4_0_0&checkin=2025-01-14&checkout=2025-01-16&dest_id=-519190&dest_type=city&dist=0&group_adults=2&group_children=0&hapos=1&highlighted_blocks=696167801_328696091_4_0_0&hpos=1&matching_block_id=696167801_328696091_4_0_0&no_rooms=1&req_adults=2&req_children=0&room1=A%2CA&sb_price_type=total&sr_order=popularity&sr_pri_blocks=696167801_328696091_4_0_0__100000&srepoch=1736185256&srpvid=72197c3b4b070c06&type=total&ucfs=1',
    contestPrizeLinkWWWItem03: 'https://ohworldstore.pl/produkt/moj-podrozniczy-dziennik',
    contestPrizeLinkWWWItem04: 'https://veolibotanica.pl/',
    contestPrizeNameItem01: '@ryanair',
    contestPrizeNameItem02: '@moderndomki',
    contestPrizeNameItem03: '@ohworld.store',
    contestPrizeNameItem04: '@veoli_botanica',
    contestPrizeDetails01: `Do wygrania <strong>1x bilet lotniczy</strong> Ryanair w formie karty podarunkowej o <strong>wartości 1200 zł</strong> – idealny na niezapomnianą podróż do jednego z wielu kierunków oferowanych przez tę popularną linię lotniczą. 
<br/><br/>Kartę podarunkową można wykorzystać na zakup biletów lotniczych na dowolne trasy obsługiwane przez Ryanair.
<br/><br/>
 To doskonała okazja, aby odwiedzić ulubione miejsce i <strong>skorzystać z zakupionych e-booków o Hiszpanii, Włoszech, Albanii, Danii, Grecji, Anglii, Chorwacji, Szwecji czy Norwegii</strong>, a także do wielu innych popularnych państw w całej Europie.
<br/><br/>
Karta podarunkowa jest ważna przez rok od dnia otrzymania pełnych danych osoby, która ją wygrała.
<br/><br/>Skorzystaj z tej szansy i spełnij swoje podróżnicze marzenia!`,
    contestPrizeDetails02: `Do wygrania <strong>2x pobyt dla 4 osób</strong> w domkach nad jeziorem Nyskim (2 noce w domku Brda lub Modern) – z wyłączeniem długich weekendów, świąt oraz miesięcy: czerwiec, lipiec, sierpień. Jeden pobyt o łącznej <strong>wartości 1000 zł</strong>.
<br/><br>Uczestnik otrzyma voucher na pobyt, który będzie można zrealizować do 31 grudnia 2025 roku. Dodatkowe infromacje w <a href="${ASSETS_DOCS}/travel_pack_regulamin_konkursu.pdf" target="_blank">regulaminie konkursu.</a>
`,
    contestPrizeDetails03: `To osobiste archiwum Twoich niezapomnianych przygód, stworzone przez podróżników dla podróżników.
<br/><br/>
W tym praktycznym i estetycznym bullet journalu znajdziesz wszystko to, czego potrzebujesz, by utrwalić swoje podróżnicze przeżycia: checklisty, mapki, najciekawsze lokalizacje w danych regionach świata i najważniejsze – strony poświęcone konkretnym państwom, które staną się idealnym „domem” dla Twoich bezcennych wspomnień. <a href="${ASSETS_DOCS}/travel_pack_regulamin_konkursu.pdf" target="_blank">Regulamin konkursu.</a>
<br/><br/>`,
    contestPrizeDetails04: ` Do wygrania <strong>3x zestawy kosmetyków</strong> dla podróżników od <a href="https://www.instagram.com/veoli_botanica" target="_blank" rel="noreferrer">Veoli Botanica</a> – idealne dla osób ceniących naturalną pielęgnację i wygodę podczas podróży. 
 Każdy zestaw zawiera starannie wyselekcjonowane produkty o <strong>wartości 515 zł</strong>, które pomogą zadbać o skórę nawet w najbardziej wymagających warunkach. <br /><br />
 Kosmetyki zostały stworzone z myślą o miłośnikach aktywnego stylu życia, którzy chcą mieć pod ręką wysokiej jakości, <strong>naturalne produkty do pielęgnacji</strong>.
<br /><br />
Wysyłka nagród realizowana jest wyłącznie na terenie Polski i nastąpi najpóźniej w terminie do 14 dni roboczych od momentu zakończenia konkursu w terminie określonym w <a href="${ASSETS_DOCS}/travel_pack_regulamin_konkursu.pdf" target="_blank">regulaminie konkursu</a>.
    `,
};

export default translations;
