import styled from 'styled-components';
import { fadeIn } from '@/styled';

const PreviewWrapper = styled.div`
    width: 100%;
    border-radius: ${ ( { theme } ) => theme.borderRadiusM };
    background-color: ${ ( { theme } ) => theme.ui01 };
    box-shadow: ${ ( { theme } ) => theme.boxShadow };
    text-align: center;
    min-width: 180px;
    min-height: 180px;
    margin: ${ ( { theme } ) => `${ theme.spacing05 } ${ theme.spacing06 } ${ theme.spacing06 } ${ theme.spacing06 }` };
    padding: 0;
    overflow: hidden;
    animation: ${ fadeIn } 0.5s ease-out forwards;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
        min-width: 100%;
    }

    @media print {
        box-shadow: none;
        border: 1px solid ${ ( { theme } ) => theme.ui09 };
        background: ${ ( { theme } ) => theme.ui10 };
    }

}`;

export default PreviewWrapper;
