import styled from 'styled-components';


const NavigationWrapper = styled.nav`
    position: relative;
    display: flex;
    min-height: 40px;
    min-width: 40px;
    margin: 0 0 0 ${ ( { theme } ) => theme.spacing06 };
    flex-direction: row;

    @media print {
        display: none;
    }
`;

export default NavigationWrapper;
