import styled from 'styled-components';

export const DiscountBrandName = styled.a`
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    font-size: ${ ( { theme } ) => theme.fontSizeM };
    text-align: center;
    width: 100%;
    margin-bottom: ${ ( { theme } ) => theme.spacing06 };
    margin-top: ${ ( { theme } ) => theme.spacing08 };
    display: block;
    text-decoration: none;
`;

export default DiscountBrandName;
