import styled from 'styled-components';

export const DiscountValidDate = styled.div`
    color: ${ ( { theme } ) => theme.text01 };
    font-size: ${ ( { theme } ) => theme.fontSizeXXS};
    white-space: nowrap;
    text-align: left;
`;

export default DiscountValidDate;
