import React, { useState } from 'react';

import {
    PreviewContent,
    PreviewImage,
    PreviewSecondaryText,
    PreviewText,
    PreviewTitle,
    PreviewWrapper
} from '../styled';

import Avatar, { AvatarGroup } from '@/components/Avatar';
import { paths } from '@/constants';
import EbookDetails from '@/views/Ebooks/components/EbookDetails';
import Modal from '@/components/Modal';

const Preview = ( {
                      index,
                      avatar,
                      cover,
                      description,
                      title,
                      location,
                      eBookType,
                      author,
                      pages,
                      price,
                  } ) => {
    const profileImgPath = avatar && `${ paths?.AUTHORS_AVATAR }/${ avatar[ 0 ] }`;
    const [ isDetailsOpened, setOpenDetails ] = useState( false );
    const handleOpenModal = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push( {
            'event': 'button_click',
            'dataId': 'ebook-button-preview',
            'dataValue': author,
        } );
        setOpenDetails( true );
    };

    const handleCloseModal = () => {
        setOpenDetails( false );
    };

    const handleOnCoverError = ( e ) => {
        e.target.src = `${ paths?.ASSETS }/ebook_placeholder_cover.jpg`;
    };

    return (
        <>
            <PreviewWrapper index={index} onClick={ handleOpenModal }>
                <PreviewImage>
                    <img
                        src={ cover }
                        title={ title }
                        alt={ title }
                        onError={ handleOnCoverError }
                    />
                </PreviewImage>
                <AvatarGroup offsetTop="-40px">
                    {
                        ( Array.isArray( avatar ) ? avatar : [ avatar ] ).map( ( item, index ) => {
                            const profileImgPath = item ? `${ paths?.AUTHORS_AVATAR }/${ item }` : null;
                            return (
                                <Avatar
                                    key={ index }
                                    order={ index }
                                    title={ Array.isArray( author ) ? author[ index ] : author }
                                    src={ profileImgPath }
                                />
                            );
                        } )
                    }
                </AvatarGroup>
                <PreviewContent>
                    <PreviewTitle>{ location ? location : eBookType }</PreviewTitle>
                    <PreviewSecondaryText>{ price }</PreviewSecondaryText>
                </PreviewContent>
                <PreviewContent>
                    <PreviewText>
                        {
                            Array.isArray( author )
                                ? author.map( ( item, index ) => (
                                    `${ item }${ index < author.length - 1 ? ', ' : '' }`
                                ) ).join( '' )
                                : author
                        }
                    </PreviewText>
                    <PreviewText>{ pages }</PreviewText>
                </PreviewContent>
            </PreviewWrapper>
            <Modal
                open={ isDetailsOpened }
                onClose={ handleCloseModal }
                customStyles={ {
                    modal: { maxWidth: '95%' },
                } }
            >
                <EbookDetails
                    avatar={ profileImgPath }
                    description={ description }
                    title={ title }
                    author={ author }
                    cover={ cover }
                    price={ price }
                />
            </Modal>
        </>
    );
};

export default Preview;
