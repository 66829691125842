import React from 'react';

import Main from '@/components/Main';
import Header from '@/components/Header';
import Spacer from '@/components/Spacer';
import Footer from '@/components/Footer';
import StickyCountdown from '@/components/StickyCountdown';

import DiscountsList from '@/views/DiscountsList';

export const Discounts = () => (
    <>
        <Header/>
        <Main>
            <DiscountsList />
            <Spacer />
        </Main>
        <Footer/>
        <StickyCountdown/>
    </>
);


export default Discounts;
