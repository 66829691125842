import React from 'react';

import Main from '@/components/Main';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import StickyCountdown from '@/components/StickyCountdown';

import Steps from '@/views/Steps';
import Banner from '@/views/Banner';
import EbookList from '@/views/Ebooks';
import Authors from '@/views/Authors';
import Summary from '@/views/Summary';
import Advantages from '@/views/Advantages';
import Testimonials from '@/views/Testimonials';
import DiscountBanner from '@/views/DiscountBanner';

import { Row } from '@/components/Layout';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import translations from '@/views/ContestPrize/constants/translations';

export const Home = () => (
    <>
        <Header/>
        <Main>
            <Banner/>
            <Advantages/>
            <EbookList/>
            <DiscountBanner/>
            <Row>
                <Button as="a" variant="secondary" dataId="discounts-button-show-more" href="znizki">
                    { translations.discountButtonMore }
                </Button>
                <Spacer size="small"/>
            </Row>
            <Steps/>
            <Authors/>
            <Testimonials/>
            <Summary/>
        </Main>
        <Footer/>
        <StickyCountdown/>
    </>
);


export default Home;
