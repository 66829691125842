export const translations = {
    discountsHeadline: `Zgarnij Pakiet Zniżek`,
    discountsSubHeadline: `Kup Paczkę E-booków`,
    discountsCode: `KOD PROMOCYJNY`,
    discountsValid: `Ważny`,
    discountsStartDate: ` od `,
    discountsEndDate: ` do `,
    discountsBrandWWW: `WWW`,
    discountsBrandSOCIAL: `IG`,
    discountDisclaimer: '* zniżki nie łączą się z innymi promocjami '
};

export default translations;
