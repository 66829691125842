import styled from 'styled-components';


export const DiscountBrandAvatarGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 70%;
    margin: 0 auto;

    & > * {
        margin: ${ ( { theme } ) => theme.spacing08 };
    }

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        max-width: 100%;
    }
`;

export default DiscountBrandAvatarGroup;
