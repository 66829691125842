import styled from 'styled-components';
import getSize from '../utils/getSize';

export const AvatarWrapper = styled.div`
    align-items: center;
    background-image: linear-gradient(45deg,
    #ffa95f 5%,
    #f99c4a 15%,
    #f47838 30%,
    #e75157 45%,
    #d92d7a 70%,
    #cc2a92 80%,
    #c32e92 95%);
    box-sizing: border-box;
    ${({ size }) => getSize({ size })};
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 2px;
    position: relative;
    margin: 0 0 0 ${ ( {  order } ) => `-${order * 10}px`};
    
    &,
    & > img {
        border: 2px solid #FFF;
        border-radius: 50%;
    }

    & > img {
        border-width: calc(0.5 * 2px);
        height: auto;
        margin: 0;
        transition: transform 0.6s ease-in-out;
        width: 100%;
    }
    
    img {
        width: 100%;
        height: 100%;
    }
`;

export default AvatarWrapper;
