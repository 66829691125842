export const SITE_NAME = `TravelPack`;
export const SITE_CONTACT_EMAIL = 'pomoc@travelpack.shop';
export const SITE_SOCIAL_LINK_IG = 'https://instagram.com/travelpack.shop';
export const CONTEST_SOCIAL_LINK = 'https://www.instagram.com/p/DEoxRt5ojyJ/?img_index=1';

export const DOWNLOAD_URL = '';
// First Promo 2024-03-07 23:59:59 +1h (Poland)
// export const PROMOTION_DATE_END = 1709852399000;
// Second Promo 2024-04-11 23:59:59 +1h (Poland)
// export const PROMOTION_DATE_END = 1712872799000;
export const PROMOTION_DATE_END = 1739228399000;

export const COOKIE_CONSENT = 'consentMode';
export const LOCALSTORAGE_DISMISS_REMINDER_SUBSCRIBE = 'dismissReminderSubscribe';
