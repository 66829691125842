export const colorPalette = {
    white: '#ffffff',
    blue100: '#5e6282',
    blue300: '#181e4b',
    gray50: '#f7f7f7',
    gray100: '#faf9f9',
    gray200: '#a4a4a4',
    gray300: '#555555',
    yellow100: '#fdb927',
    yellow300: '#ffc446',
    orange300: '#d9634b',
    green300: '#245a3f',
    green600: '#0d3532',
};

export default colorPalette;
