import styled from 'styled-components';

export const DiscountCode = styled.div`
    color: ${ ( { theme } ) => theme.text01 };
    line-height: ${ ( { theme } ) => theme.fontLinHeightRegular };
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    font-size: ${ ( { theme } ) => theme.fontSizeM };
    text-align: left;
    width: 100%;
    display: block;
`;

export default DiscountCode;
