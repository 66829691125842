import React from 'react';

import {
    PreviewImage,
    PreviewSecondaryText,
    PreviewText,
    PreviewTitle,
    PreviewWrapper
} from '@/components/Preview/styled';

import Avatar, { AvatarGroup } from '@/components/Avatar';
import { paths } from '@/constants';
import translations from '../constants/translations';
import { DiscountBrandName, DiscountCode, DiscountContent, DiscountValidDate } from '../styled';

const DiscountItem = ( {
                           index,
                           avatar,
                           image,
                           description,
                           discountCode,
                           discountStartDate,
                           discountEndDate,
                           headline,
                           brandName,
                           price,
                           profileUrl,
                           socialLink,
                           discountInstruction,
                           showPromoCode,
                       } ) => {

    const handleOnCoverError = ( e ) => {
        e.target.src = `${ paths?.ASSETS }/ebook_placeholder_cover.jpg`;
    };

    return (
        <>
            <PreviewWrapper index={ index }>
                <PreviewImage>
                    <img
                        src={ image }
                        title={ headline }
                        alt={ headline }
                        onError={ handleOnCoverError }
                    />
                </PreviewImage>
                <AvatarGroup offsetTop="-70px">
                    {
                        ( Array.isArray( avatar ) ? avatar : [ avatar ] ).map( ( item, index ) => {
                            const profileImgPath = item ? `${ paths?.AUTHORS_AVATAR }/${ item }` : null;
                            return (
                                <a href={ socialLink || profileUrl } target="_blank" rel="noreferrer">
                                    <Avatar
                                        size="large"
                                        key={ index }
                                        order={ index }
                                        title={ Array.isArray( brandName ) ? brandName[ index ] : brandName }
                                        src={ profileImgPath }
                                    />
                                </a>
                            );
                        } )
                    }
                </AvatarGroup>
                <DiscountBrandName href={ socialLink } target="_blank"
                                   rel="noreferrer">{ brandName }</DiscountBrandName>
                <DiscountContent>
                    <PreviewTitle>{ description }</PreviewTitle>
                    <PreviewSecondaryText>{ price }</PreviewSecondaryText>
                </DiscountContent>
                { !showPromoCode && <DiscountContent>
                    <PreviewText>
                        { profileUrl && ( <a href={ profileUrl } target="_blank"
                                             rel="noreferrer">{ translations.discountsBrandWWW }</a> ) }
                        {profileUrl && socialLink && " | "}
                        { socialLink && ( <a href={ socialLink } target="_blank"
                                             rel="noreferrer">{ translations.discountsBrandSOCIAL }</a> ) }
                    </PreviewText>
                </DiscountContent> }
                { showPromoCode && <DiscountContent>
                    <DiscountCode>
                        <DiscountValidDate>
                            { translations.discountsCode }
                        </DiscountValidDate>
                        { discountCode }
                    </DiscountCode>
                    <DiscountValidDate>
                        { translations.discountsValid }
                        { discountStartDate && translations.discountsStartDate }
                        { discountStartDate }
                        { translations.discountsEndDate }
                        { discountEndDate }
                    </DiscountValidDate>
                </DiscountContent> }
                { showPromoCode && discountInstruction && <DiscountContent>
                    <DiscountValidDate dangerouslySetInnerHTML={ { __html: discountInstruction } }/>
                </DiscountContent> }
            </PreviewWrapper>
        </>
    );
};

export default DiscountItem;
